<template>
  <div class="container-fluid products-create">
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-sm">
          <div class="card-header">
            <div class="col-auto my-auto d-flex align-items-center">
              <h5>Add product</h5>
            </div>
          </div>

          <div class="card-body">
            <div v-if="!loading" class="row mt-4 mb-4">
              <div class="col">

                <form @submit.prevent="saveProduct">
                  <div class="row mb-3">
                    <div class="col-6">
                      <label for="title">Title*</label>
                      <input
                        type="text"
                        id="title"
                        v-model="product.title"
                        class="form-control"
                        placeholder="Title"
                        required
                      />
                    </div>

                    <div class="col-6">
                        <label for="category_id">Category</label>
                        <select
                          id="category_id"
                          v-model="product.category_id"
                          class="form-control"
                        >
                          <option :value="null">None</option>
                          <option v-for="category in categories" :key="category.id" :value="category.id">
                            {{ category.title }}
                          </option>
                        </select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-6">
                      <label for="brand">Brand</label>
                      <input
                        type="text"
                        id="brand"
                        v-model="product.brand"
                        class="form-control"
                        placeholder="Brand"
                      />
                    </div>

                    <div class="col-6">
                      <label for="sku">SKU</label>
                      <input
                        type="text"
                        id="sku"
                        v-model="product.sku"
                        class="form-control"
                        placeholder="SKU"
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-6">
                      <label for="price">Price*</label>
                      <input
                        type="number"
                        min="1"
                        step="0.01"
                        id="price"
                        v-model="product.price"
                        class="form-control"
                        placeholder="Price"
                        required
                      />
                    </div>

                    <div class="col-6">
                      <label for="clinic_tax_id">Tax</label>
                      <select
                        id="clinic_tax_id"
                        v-model="product.clinic_tax_id"
                        class="form-control"
                      >
                        <option :value="null" selected>No tax included</option>
                        <option v-for="tax in user.clinic.taxes" :key="tax.id" :value="tax.id">
                          {{ tax.name }} ({{ tax.rate }}% inclusive)
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-6">
                      <label for="in_stock">In stock</label>
                        <select
                          id="in_stock"
                          v-model="product.in_stock"
                          class="form-control"
                        >
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                    </div>
                  </div>

                  <div class="row mb-5">
                    <div class="col-12">
                      <label for="description">Description</label>
                      <textarea
                        id="description"
                        v-model="product.description"
                        class="form-control"
                        placeholder="Description"
                      ></textarea>
                    </div>
                  </div>

                  <div class="row pagination">
                    <div class="col">
                        <button
                          type="button"
                          @click.stop="backToList()"
                          :disabled="loading"
                          class="btn btn-outline-secondary btn-sm btn-back me-2">
                          <i class="far fa-arrow-left me-1"></i>
                          Back
                        </button>
                      </div>

                      <div class="col text-end">
                        <button
                          type="submit"
                          :disabled="loading"
                          class="btn btn-outline-primary btn-sm"
                        >
                          <i class="far fa-save me-1"></i> Save
                        </button>
                      </div>
                    </div>
                </form>

              </div>
            </div>

            <div v-else class="card border-0 shadow-none mb-4 pt-3">
              <loading></loading>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import Loading from "@/views/components/loader/Loading";

  export default {
    props: [],
    data() {
      return {
        categories: [],
        loading: false,
        product: {
          brand: '',
          category_id: null,
          clinic_tax_id: 1,
          currency_id: 1,
          description: '',
          in_stock: 1,
          price: null,
          sku: '',
          title: '',
          vat_status: 'inclusive'
        },
      }
    },
    methods: {
      backToList() {
        this.$router.push('/settings/products');
      },
      fetchCategories() {
        var url = process.env.VUE_APP_API_URL + "/products/categories";

        this.$axios.get(
          url,
          { params: { 'paginated': false }}
        ).then(({ data }) => {
          this.categories = data.data;
        });
      },
      saveProduct() {
        var url = process.env.VUE_APP_API_URL + "/products/custom";

        this.$axios.post(
          url,
          {
            brand: this.product.brand,
            category_id: this.product.category_id,
            clinic_tax_id: this.product.clinic_tax_id,
            currency_id: this.product.currency_id,
            description: this.product.description,
            in_stock: this.product.in_stock,
            price: this.product.price,
            sku: this.product.sku,
            title: this.product.title,
            vat_status: this.product.clinic_tax_id === null ? 'exempt' : 'inclusive'
          }
        ).then(({ data }) => {
          if (data.message === "Product created") {
            this.$router.push("/settings/products");
          }
          this.$EventBus.$emit("alert", data);
        });
      },
    },
    created() {
      this.fetchCategories();
    },
    computed: {
      user() {
        return this.$store.getters['auth/user'];
      },
      path() {
        return this.$stash.path;
      }
    },
    components: {
      Loading
    }
  }
</script>

<style scoped>
  textarea {
    min-height: 150px;
  }

  @media (max-width: 768px) {
    .col-6 {
      flex: 0 0 100%;
      max-width: 100%
    }

    .col-6 > label {
      margin-top: 1rem;
    }

    .pagination {
      flex-direction: column;
    }

    .btn-back, .btn-outline-primary,
    .btn-outline-danger {
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }
</style>